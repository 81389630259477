<template>
  <div class="pa-2">
    <v-card class="pa-1 mb-2" flat :loading="loadingstatus">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="btnload"
          >Load Data

          <v-icon>mdi-file-pdf-outline</v-icon>
        </v-btn>

        <v-btn color="primary" @click="btnzip"
          >Load Zip

          <v-icon>mdi-file-pdf-outline</v-icon>
        </v-btn>

        <v-btn
          color="success"
          v-simple-print="'panAllData'"
          v-if="desserts.length > 0"
          >Download

          <v-icon>mdi-file-pdf-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="pa-1 mb-2" flat v-if="desserts.length > 0">
      <div id="panAllData">
        <table>
          <tbody>
            <tr>
              <th class="thheader">SNO</th>
              <th class="thheader">Photo</th>
              <th class="thheader">Personal Details</th>
              <th class="thheader">ID</th>
              <th class="thheader">Company / Designation</th>
              <th class="thheader">Card Details</th>
              <!-- <th style="text-align: center;width: 10px !important;font-size: 9px;font-family: Arial;padding:8px 0px;border:1px solid #ccc;">Card Details</th> -->
            </tr>

            <tr v-for="item in desserts" :key="item.s_no">
              <td class="thdata" style="text-align: center; width: 10px">
                {{ item.s_no }}
              </td>
              <td
                class="thdata"
                style="text-align: center; width: 10px !important"
              >
                <img
                  :src="$apiPath + item.picHeadShot"
                  height="60px"
                  width="60px"
                  style="border-radius: 10px; border: 1px solid #ccc"
                  type="button"
                />
              </td>

              <td class="thdata">
                Reg.No- {{ item.app_id }}<br />
                {{ item.fName }} {{ item.lName }}<br />
                {{ item.mobile }}
              </td>

              <td class="thdata">
                {{ item.idProofType }}<br />
                {{ item.idProofNumber }}
              </td>

              <td class="thdata">
                {{ item.company_name }}<br />
                {{ item.jobtype_name }}
              </td>

              <td class="thdata">
                {{ item.card_name }}<br />
                {{ item.print_text }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-card>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
//import axios from "axios";
import JSZip from "jszip";
import saveAs from "save-as";
import JSZipUtils from "jszip-utils";

export default {
  data() {
    return {
      desserts: [],
      ReportFor: "",
      loadingstatus: false,
      imgData: "",
      i: 0,
    };
  },

  mounted() {
    this.$store.commit("setPageTitle", "Police Report");
    this.$store.commit("setPageTitleIcon", "mdi-file-chart");
  },
  methods: {
    exportPDF() {
      window.html2canvas = html2canvas;
      var doc = new jsPDF("p", "pt", "a4");
      doc.html(document.querySelector("#panAllData"), {
        callback: function (pdf) {
          pdf.save("PoliceReport.pdf");
        },
      });
    },
    btnload: function () {
      this.Reload();
    },
    btnzip: function () {
      const urls = [];
     

      for (this.i = 0; this.i <= this.desserts.length - 1; this.i++) {
        urls.push(
          this.$apiPath + this.desserts[this.i]["picHeadShot"].charAt(0).toUpperCase() + this.desserts[this.i]["picHeadShot"].substring(1)
        );
      }

     

      console.log(urls);

      const zip = new JSZip();
      let count = 0;
      const zipFilename = "MyData.zip";
      urls.forEach(async function (url) {
        const urlArr = url.split("/");
        const filename = urlArr[urlArr.length - 1];
        try {
          const file = await JSZipUtils.getBinaryContent(url);
          zip.file(filename, file, { binary: true });
          count++;
          if (count === urls.length) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, zipFilename);
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    Reload: async function () {
      this.loadingstatus = true;
      this.overlay = true;
     await this.$axios
        .get(
          this.$apiPath +
            "Report/PoliceAccrdReport/" +
            this.$store.state.eventInfo.EventId +
            "/" +
            this.$store.state.eventInfo.VenueId +
            "/" +
            this.$store.state.eventInfo.venuetype +
            "/" +
            this.$store.state.eventInfo.EventAdminID
        )
        .then((res) => {
          console.log(res.data.result);
          this.desserts = res.data.result;
          this.loadingstatus = false;
        })
        .catch()
        .finally();
    },
  },
};
</script>
<style scoped>
.thheader {
  background-color: rgba(0, 132, 255, 0.658);
  text-align: center;
  width: 10px !important;
  font-size: 9px;
  font-family: Arial;
  padding: 8px 0px;
  border: 1px solid #ccc;
}
.thdata {
  text-align: center;

  padding: 8px 0px;
  border: 1px solid #ccc;
}
</style>


